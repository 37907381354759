import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

interface LoadingProps {
  fillScreen?: boolean;
  text?: string;
}

export default function Loading(props: LoadingProps){

    const progress = <CircularProgress />;
    const loadingText = props.text ? <Typography variant="subtitle1">{props.text}</Typography> : null;

    return props.fillScreen ?
        <div style={{display: "flex", flexDirection: "column", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
            <div>
                {progress}
            </div>
            {loadingText}
        </div>
        :
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            {progress}
            <div>
                {loadingText}
            </div>
        </div>;

}
