import PublishedRaceResult, {SplitItem} from "../models/published-race-result";
import Stack from "@mui/material/Stack";
import Header from "../components/header";
import {GridColDef} from "@mui/x-data-grid";
import {useLocation} from "react-router-dom";
import {StyledDataGrid} from "../components/styled-data-grid";
import { prettyDuration } from "../utils/date-utils";


interface SplitsPageProps {
    raceResult: PublishedRaceResult;
    splits: SplitItem[]
}

export function SplitsPage(props: SplitsPageProps) {

    const location: any = useLocation();
    const splits = (props.splits ? props.splits : location.state.splits).map((spl: any, idx: any) => ({...spl, idx}))

    const colDefSplits: GridColDef[] = [
        {
            field: "mapText",
            flex: 1,
            headerName: "Map Text",
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                // @ts-ignore: Object is possibly 'null'.
                cellParams1.api.getRow(cellParams1.id)['idx'] - cellParams2.api.getRow(cellParams2.id)['idx'],
        },
        {
            field: "pointLabel",
            flex: 1,
            headerName: 'Point',
        },
        {
            field: 'splitTime',
            flex: 1,
            headerName: 'Split',
            valueFormatter: (params) => prettyDuration(params.value as number),
        },
        {
            field: 'totalTime',
            flex: 1,
            headerName: 'Total',
            valueFormatter: (params) => prettyDuration(params.value as number),
        },
    ];


    return <div style={{width: '100%'}}>


            <Stack direction={'row'} alignItems={'center'}>
                <Header>Split Times for {props.raceResult ? props.raceResult.tagSerial : location.state.selectedResult.tagSerial}</Header>
            </Stack>
            <StyledDataGrid columns={colDefSplits} rows={splits} getRowId={row=>row['idx']}
                       autoHeight
            />
    </div>
}