import Typography from "@mui/material/Typography";

interface HeaderProps {
  children: any;
  small?: boolean;
}

export default function Header(props: HeaderProps){
  return <Typography variant={props.small ? 'h6' : 'h4'}
                     sx={{mb: props.small ? 0 : 2, mt: 0}}>{props.children}</Typography>
}
