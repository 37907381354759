import PublishedEvent from "../models/published-event-list";
import PublishedRaceResult from "../models/published-race-result";
// import { EJSON } from "bson";
export class RealmFunctions {
    // @ts-ignore
    private static readonly baseUrl: string = process.env.REACT_APP_REALM_FUNCTION_BASEURL;

    static async getEventList(orgId: string): Promise<PublishedEvent[]> {
        try {
            const response = await fetch(`${this.baseUrl}/getEventList?orgId=${orgId}`);
            const json = await response.json();

            console.log(json);

            // if JSON.stringify called on mongo:
            const publishedEvents = JSON.parse(json) as PublishedEvent[];

            // else:
            // const publishedEvents = EJSON.deserialize(json) as PublishedEvent[];

            return publishedEvents || [];
        } catch (error) {
            console.log(error);
        }
        return [];
    }

    static async getEventResultsByEventId(eventId: string): Promise<PublishedRaceResult[]> {
        try {
            const response = await fetch(`${this.baseUrl}/getEventResultsByEventId?eventId=${eventId}`);
            const json = await response.json();

            // if JSON.stringify called on mongo:
            const publishedEvents = JSON.parse(json) as PublishedRaceResult[];

            // else:
            // const publishedEvents = EJSON.deserialize(json) as PublishedEvent[];

            return publishedEvents || [];
        } catch (error) {
            console.log(error);
        }
        return [];
    }
}