import './App.css';
import ResultsPage from './pages/results-page';
import { EventsPage } from './pages/events-page';
import { Route, Routes, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import Spacer from './components/spacer';
import { ArrowBack } from '@mui/icons-material';
import logo from './logo.png'
import PublishedEvent from './models/published-event-list';
import {useState} from "react";


enum BrowserRoutes {
  EVENTS_PAGE = '/',
  RESULTS_PAGE = 'results-page'
}

function App() {

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedEvent, setSelectedEvent] = useState<PublishedEvent | undefined>(undefined);


  return (<div style={{padding: "3%"}}>
        <Spacer y={5}/>
    <Stack direction={'row'} alignItems={'center'}>
      { location.pathname !== BrowserRoutes.EVENTS_PAGE ? <Button onClick={() => navigate(-1)}> <ArrowBack/> <Spacer x={1}/> Back</Button> : null }
      <Stack direction={'row'} className='SundialBranding' alignItems={'center'} display={'flex'}>
        {/* <Typography variant='h4'>SUNDIAL</Typography> */}
        <img
          className='SundialLogo'
          src={`${logo}`}
          alt='./logo.png'
          loading="lazy"
        />    
      </Stack>
      </Stack>
    <Spacer y={2}/>
    <Routes>
      <Route path={BrowserRoutes.EVENTS_PAGE} element={
        <EventsPage onOpenEvent={function (event: PublishedEvent): void {
          setSelectedEvent(event);
          navigate(BrowserRoutes.RESULTS_PAGE, {state: {event: event}});
        } } />}/>
      <Route path={BrowserRoutes.RESULTS_PAGE + '/*'} element={
        <ResultsPage event={selectedEvent!}/>
      }/>
    </Routes>

    <Outlet/>
  </div>
  );
}

export default App;
