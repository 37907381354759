import Button from "@mui/material/Button";
import { GridColDef } from "@mui/x-data-grid";
import { RealmFunctions } from "../backend/realm-functions";
import Header from "../components/header";
import Loading from "../components/loading";
import PublishedEvent from "../models/published-event-list";
import { shortDate } from "../utils/date-utils";
import {useEffect, useState} from "react";
import {StyledDataGrid} from "../components/styled-data-grid";

interface EventsPageProps {
  onOpenEvent: (event: PublishedEvent) => void;
}




export function EventsPage(props: EventsPageProps) {

  const [events, setEvents] = useState<PublishedEvent[]>([]);

  // get the query params from the window
  const urlParams = new URLSearchParams(window.location.search);
  const orgId = urlParams.get("orgId");

  useEffect(() => {
    if (orgId === null) {
      return;
    }
    RealmFunctions.getEventList(orgId).then(setEvents);
  }, [orgId]);

  // callback
  const onOpenClick = (event: PublishedEvent) => {
    return () => {
      props.onOpenEvent(event);
    };
  }



  // todo add classes/league as field to table

  const cols: GridColDef[] = [
    {
      field: 'startDate',
      headerName: 'Event Date',
      type: 'date',
      valueFormatter: params => {
        return shortDate(new Date(params.value as string));
      },
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Event Name',
      flex: 1,
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
    },
    {
      field: 'open',
      headerName: 'Open',
      flex: 1,
      renderCell: params => <Button
          onClick={onOpenClick((params.row as PublishedEvent))}>Open</Button>,
    },

  ];

  return <div>
    <Header>EVENTS</Header>
    {events === null ? <Loading fillScreen text="Loading events..."/> : <div>
      <StyledDataGrid columns={cols} rows={events} getRowId={row => row.eventId}
                autoHeight={true} 
      />
    </div>
    }
  </div>;

}
