import {GridColDef} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { prettyDuration } from "../utils/date-utils";
import { MarkingResult } from "../models/enums/marking";
import Loading from "../components/loading";
import Header from "../components/header";
import PublishedRaceResult, {SplitItem} from "../models/published-race-result";
import { RealmFunctions } from "../backend/realm-functions";
import PublishedEvent from "../models/published-event-list";
import {SplitsPage} from "./splits-page";
import { Route, Routes, Outlet, useNavigate, useLocation } from 'react-router-dom';
import {StyledDataGrid} from "../components/styled-data-grid";

interface ResultsPageProps {
  event: PublishedEvent;
}

export default function ResultsPage(props: ResultsPageProps){

  const [selectedResult, setSelectedResult] = useState<PublishedRaceResult | undefined>(undefined);
  const [splits, setSplits] = useState<SplitItem[] | undefined>(undefined);
  const [raceResults, setRaceResults] = useState<PublishedRaceResult[]>([]);

  const navigate = useNavigate();
  const location: any = useLocation();

  useEffect(() => {
    RealmFunctions.getEventResultsByEventId(props.event ? props.event.eventId : location.state.event.eventId).then(setRaceResults);
  }, [props.event, location.state.event]);


  const colDefResults: GridColDef[] = [
    {
      field: "rankByClass",
      headerName: "Class Rank",
      filterable: false,
      valueFormatter: params => params.value === -1 ? '' : params.value,
      flex: 1
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 75,
      flex: 1
    },
    {
      field: "tagSerial",
      headerName: "TAG",
      minWidth: 75,
      flex: 1
    },
    {
      field: 'clubName',
      headerName: 'Club',
      minWidth: 75,
      flex: 1
    },
    {
      field: 'class',
      headerName: 'Class',
      valueGetter: params => (params.row as PublishedRaceResult).class,
      flex: 1
    },
    {
      field: "totalDuration",
      headerName: "Time", filterable: false,
      valueFormatter: params => prettyDuration(params.value as number),
      minWidth: 75,
      flex: 1
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 75,
      valueFormatter: params => params.value===MarkingResult.QUALIFIED ? 'Qualified' : 'Disqualified',
      flex: 1
    },
    {
      field: 'tools',
      headerName: 'Tools',
      renderCell: params => <div>
      <Button onClick={() => {
        setSplits((params.row as PublishedRaceResult).splits);
        setSelectedResult((params.row as PublishedRaceResult));
        navigate('/results-page/splits-page', {state: {event: location.state.event , splits: splits, selectedResult: (params.row as PublishedRaceResult)}});
      }}>open</Button>
      </div>
    }
  ];

  return <div style={{display: 'flex'}}>



      <Routes>
        <Route path={'/'} element={
          <div style={{width: '100%'}}>
            { raceResults ? <>

            <Stack direction="row" alignItems={'center'}>
              <Header>RESULTS FOR EVENT: {props.event ? props.event.name : location.state.event.name}</Header>
            </Stack>
            <StyledDataGrid columns={colDefResults} rows={raceResults}  getRowId={row => row.raceResultId}
                      autoHeight disableRowSelectionOnClick/>

                </>
                : <Loading fillScreen text="Loading results" />}
          </div>} />
        <Route path={'splits-page'} element={<SplitsPage splits={splits!} raceResult={selectedResult!}/>}/>

      </Routes>
        <Outlet />

  </div>
}