
export function shortDate(date: Date): string{
  return date.toLocaleDateString('en-ZA', {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export interface GQLDate {
  $date: {
    $numberLong: string
  }
}

export function convertGraphQLDate(date: GQLDate): Date{
  return new Date(parseInt(date.$date.$numberLong, 10));
}

function zPad(int: number, targetWidth: number = 2): string {
  let str = int.toString(10);
  for(let i = 0; i < (targetWidth-str.length); i++)
    str = '0' + str;
  return str;
}

export function toGraphQLDateStr(date: Date): string {
  const month = zPad(date.getUTCMonth()+1); // 0 - 11 is the default range
  const dayOfMo = zPad(date.getUTCDate());
  const h = zPad(date.getUTCHours());
  const min = zPad(date.getUTCMinutes());
  const sec = zPad(date.getUTCSeconds());
  const millis = zPad(date.getUTCMilliseconds(), 3);

  return `${date.getUTCFullYear()}-${month}-${dayOfMo}T${h}:${min}:${sec}.${millis}+00:00`
}

/**
 * Calculate a human's year age based on their date of birth.
 * The actual calendar is checked. Not the amount of milliseconds since dob.
 * @param dob The human's date of birth
 */
export function humanAge(dob: Date): number {
  const today = new Date();
  let years = today.getUTCFullYear() - dob.getUTCFullYear();
  if (today.getUTCMonth() >= dob.getUTCMonth() && today.getUTCDay() >= dob.getUTCDay())
    years++;
  return years;
}

/**
 * 
 * @param duration duration of time in milliseconds
 * @returns string hh:mm:ss
 */
export function prettyDuration (duration: number): string {
  const sign = duration < 0 ? "-" : "";

  duration = Math.abs(duration);
  //let mil = duration % 1000;
  const secRemaining = Math.floor(duration/1000);
  const sec = secRemaining % 60;
  const minRemaining = Math.floor(secRemaining/60);
  const min = minRemaining % 60;
  const hr = Math.floor(minRemaining/60);


  let seconds: string;
  let minutes: string;
  let hours: string;
  if (sec > 9){
      seconds = ""+sec;
  } else {
      seconds = "0"+sec;
  }
  if (min > 9){
      minutes = ""+min;
  } else {
      minutes = "0"+min;
  }
  if (hr > 9){
      hours = ""+hr;
  } else {
      hours = "0"+hr;
  }

  return sign+" "+hours+":"+minutes+":"+seconds;
}

/**
 * 
 * @param duration duration in milliseonds
 * @returns will return a date. The time is what we want here, so date is set to 11/11/1998
 */
export function millisToTime (duration: number): Date {
  //let mil = duration % 1000;
  let secRemaining = Math.floor(duration/1000);
  let sec = secRemaining % 60;
  let minRemaining = Math.floor(secRemaining/60);
  let min = minRemaining % 60;
  let hr = Math.floor(minRemaining/60);
  
  return new Date(1998,11,11,hr,min,sec);
}

/**
 * 
 * @param date input date
 * @returns returns the amount of milliseconds from 00:00:00 to date. Obviously this is only valid for a time below 24 hours long (24:59:59)
 */
export function compute24HourMilliseconds(date: Date): number {

  let zeroTime = new Date(date);
  zeroTime.setHours(0);
  zeroTime.setMinutes(0);
  zeroTime.setSeconds(0);

  console.log('beep ', date.getTime(), ' ', zeroTime.getTime())

  return (date.getTime() - zeroTime.getTime());
  
}

// copied from std lib
export interface DateTimeFormatOptions {
  localeMatcher?: "best fit" | "lookup" | undefined;
  weekday?: "long" | "short" | "narrow" | undefined;
  era?: "long" | "short" | "narrow" | undefined;
  year?: "numeric" | "2-digit" | undefined;
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
  day?: "numeric" | "2-digit" | undefined;
  hour?: "numeric" | "2-digit" | undefined;
  minute?: "numeric" | "2-digit" | undefined;
  second?: "numeric" | "2-digit" | undefined;
  timeZoneName?: "long" | "short" | undefined;
  formatMatcher?: "best fit" | "basic" | undefined;
  hour12?: boolean | undefined;
  timeZone?: string | undefined;
}

export function customDateFormat(date: Date, formatParams: DateTimeFormatOptions[], delim: string): string{
  return formatParams.map(frm =>
    new Intl.DateTimeFormat('en-ZA', frm).format(date)
  ).join(delim);
}
